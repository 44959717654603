import { FILTERS_NAME } from '@/const';
import { useAppDispatch } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { mainMenuActions } from '@/fsd/entities/MainMenu';
import { useProductFilters } from '@/fsd/pages/ProductsPage/lib/hooks/useProductFilters';
import { AgeItemType } from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter';
import { AgeItemTypeMenu } from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter/types/AgeItemType';

/**
 * Змінює кількість відбражених фільтрів в лівій панелі. Щось ховає, щось відображає
 * @returns
 */
export const useClickMenuItem = () => {
  const { clearAndUpdateFiltersByKeyRTKQ } = useProductFilters();
  const dispatch = useAppDispatch();

  const onClick = (item: AgeItemTypeMenu) => {
    const filterTypeName: string = FILTERS_NAME.PRODUCT_CATEGORY;

    const itemCast: AgeItemType = {
      ...item,
      checked: true,
    };
    clearAndUpdateFiltersByKeyRTKQ({ item: itemCast, key: filterTypeName });

    dispatch(mainMenuActions.setSelectedMenuItem(item));
  };

  return {
    onClickMenuItem: onClick,
  };
};
