import { AgeItemType } from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter';

interface IProps {
  parentId: number;
  allData: AgeItemType[];
}

/**
 *
 * @param param0
 * @returns Попвертає айдішки всіх дочірніх елементів. Не включаючи parentId
 */
export function getNodesIdsByParentId({ parentId, allData }: IProps) {
  let arr: number[] = [];

  arr = recursion({ parentId, allData });

  return arr;

  function recursion({ parentId, allData }: IProps) {
    const childrenLevel2 = allData.filter((i) => {
      if ('parent_id' in i) {
        return i.parent_id === parentId;
      } else {
        return false;
      }
    });

    if (childrenLevel2.length > 0) {
      const ids = childrenLevel2.map((i) => i.id);
      arr = arr.concat(...ids);
      ids.forEach((id) => recursion({ parentId: id, allData }));
    }

    return arr;
  }
}
