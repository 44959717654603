import { Node } from 'react-checkbox-tree';

import { getUpdatedFiltersByURL } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/config/restoreProductsFilterFromUrl';
import { IFiltersRTKQ } from '@/fsd/entities/ProductFilters/model/types/filtersRTKQType';
import { AgeItemType } from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter';

import { getNodesIdsByParentId } from './getTreeNodesByParentId';

// https://stackoverflow.com/questions/78094183/react-checkbox-tree-icons-not-shown-properly

export function getCheckboxFromUrl({
  filtersRTKQ,
  searchParam,
  filterTypeName,
}: {
  filtersRTKQ: IFiltersRTKQ;
  searchParam: URLSearchParams;
  filterTypeName: string;
}) {
  const changeableFiltersRTKQ: IFiltersRTKQ = getClone(filtersRTKQ);

  // Усім елементам знімаємо галочку
  changeableFiltersRTKQ[filterTypeName].values.forEach(
    (_) => (_.checked = false)
  );
  const { filtersRTKQ: filtersRTKQUpdatedByURL } = getUpdatedFiltersByURL(
    changeableFiltersRTKQ,
    searchParam
  );

  let selectedCheckboxFromUrl: string[] = [];
  // const filterNameValues =
  //   searchParam.keys.length === 0
  //     ? changeableFiltersRTKQ[filterTypeName].values
  //     : filtersRTKQUpdatedByURL[filterTypeName].values;
  const filterNameValues = filtersRTKQUpdatedByURL[filterTypeName].values;

  filterNameValues.forEach((_) => {
    if (_.checked === true) {
      selectedCheckboxFromUrl.push(_.id.toString());

      const ids = getNodesIdsByParentId({
        parentId: _.id,
        allData: filterNameValues,
      });

      selectedCheckboxFromUrl = selectedCheckboxFromUrl.concat(ids.map(String));
    }
  });

  const unique = [...new Set(selectedCheckboxFromUrl)];

  return unique;
}

/**
 *
 * @param rootNodes nodes for which build children. 
 * [{"id": 0,"title": "Чоловіки"},{"id": 1,"title": "Жінки"}]
 * 
    [
      {"id": 34,"title": "Аксесуари для взуття","parent_id": 2},
      {"id": 35,"title": "Балетки","parent_id": 2},
      {"id": 36,"title": "Латина","parent_id": 2},
      ...
    ]
 * @param allData 
    [
      {"id":2,"title":"Взуття","parent_id":null},
      {"id":35,"title":"Балетки","parent_id":2}, 
      ...
    ]
 * @returns
 * {
    "value": "2",
    "label": "Взуття",
    "children": [
        {
            "value": "35",
            "label": "Балетки"
        },
        {
            "value": "36",
            "label": "Латина"
        }
    ]
}
 */
export function getTreeNodes(
  rootNodes: AgeItemType[],
  allData: AgeItemType[] // allCategories
) {
  const _result = rootNodes.map((_) => {
    let childrenLevel2 = allData.filter((i) => {
      if ('parent_id' in i) {
        return i.parent_id === _.id;
      } else {
        return false;
      }
    });

    const node = getNode(_);
    if (childrenLevel2.length > 0) {
      node.children = getTreeNodes(childrenLevel2, allData);
    }

    return node;
  });

  return _result;
}

function getNode(obj: AgeItemType): Node {
  return {
    value: String(obj.id),
    label: obj.title,
  };
}

// const nodes = [
//   {
//     value: 'mars',
//     label: 'Mars',
//     children: [
//       { value: 'phobos', label: 'Phobos' },
//       { value: 'deimos', label: 'Deimos' },
//     ],
//   },
// ];

export function getClone(obj: object) {
  return JSON.parse(JSON.stringify(obj));
}
