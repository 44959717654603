import { IFiltersRTKQ } from '@/fsd/entities/ProductFilters/model/types/filtersRTKQType';
import { AgeItemType } from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter/types/AgeItemType';

import { RadixItemMenu } from './widgets/RadixItemMenu';
import { RadixItemMenuAndSubItems } from './widgets/RadixItemMenuAndSubItems';

interface RenderItemMenuProps {
  item: AgeItemType;
  requestedFiltersRTKQ: IFiltersRTKQ;
}

export function RenderItemMenu(props: RenderItemMenuProps) {
  const { requestedFiltersRTKQ, item } = props;

  const subItems = requestedFiltersRTKQ.categories.values.filter(
    (child) => child.parent_id === item.id
  );

  if (subItems.length === 0) {
    return <RadixItemMenu item={item} />;
  }

  // const itemCategory = requestedFiltersRTKQ.categories.values.find(
  //   (cat) => cat.id === item.id
  // );

  return (
    <RadixItemMenuAndSubItems
      item={item}
      subItems={subItems}
      // itemCategory={itemCategory}
    />
  );
}
