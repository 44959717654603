export const RoutesNames = {
  ProductPage: '/product/',
  KeyCRM: '/keycrm',
  Cart: '/cart',
  About: '/about',
  DeliveryAndPayment: '/delivery-and-payment',
  Cooperation: '/cooperation',
  Contacts: '/contacts',
  Checkout: '/checkout',
};
