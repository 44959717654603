import { MAX_WIDTH_TABLET } from '@/const';
import { Divider, useMediaQuery } from '@mui/material';

import { FiltersUser } from '../impl/Aside/AsideFilters';
import { CardsByRTKQ } from './CardsByRTKQ';
import s from './ProductsPage.module.scss';

export function ProductsPage() {
  const isTablet = useMediaQuery(MAX_WIDTH_TABLET);

  return (
    <div className='ContentWrapper'>
      {!isTablet && <Divider />}
      <div className={s['wrap--aside--products-grid']}>
        {!isTablet && <FiltersUser />}
        <CardsByRTKQ />
      </div>
    </div>
  );
}
